// import './App.css';
// import React from 'react';
// import StoryScreen from './screen/StoryScreen';
// import withProvider from './redux/withProvider'


// function App() {
//   return (
//     <div>
//       <StoryScreen />
//     </div>
//   )
// }

// export default withProvider(App);



import './App.css';
import React from 'react';
import StoryScreen from './screen/StoryScreen';
import History from './components/History/History'
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";


function App() {

  return (

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          {/* <History /> */}
          <Routes>
            <Route path="/" element={<StoryScreen />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>

  )
}

export default App;