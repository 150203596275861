const initState = {
  loading: true,
  // data: [],
  stories: [],
  next: null
};
export default function reducer(state = initState, action) {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_STORIES": {
      console.log(action.type);
      return {
        // data: action.payload.data,
        stories: action.payload.storyList,
        loading: false,
      };
    }
    case "LOADED": {
      console.log(action.type);
      return {
        ...state,
        loading: false,
      };
    }
    case "FLUSH_STORY":
      return initState;

    default:
      return state;
  }
}
