import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStories } from "../actions/Story.action";
import { saveHistory } from "../actions/History.action";
import YoutubeComponent from "../components/YoutubeComponent";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import History from "../components/History/History";
import {
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
  FaRegStopCircle,
} from "react-icons/fa";
import { VscDebugContinue } from "react-icons/vsc";
import "../index.css";

const localStories = [
  {
    id: "540a16a9-1c70-436f-bf10-e92ae2e70424",
    index: 0,
    created_at: "2022-11-24T15:12:59.318905Z",
    vid_id: "--9Zqij_5ww",
    start_time: 61,
    end_time: 73,
    created_by: null,
  },
  {
    id: "cc5f704f-25f2-481c-aa17-061fde79ea8b",
    index: 0,
    created_at: "2022-11-24T15:12:59.318912Z",
    vid_id: "--9Zqij_5ww",
    start_time: 113,
    end_time: 128,
    created_by: null,
  },
  {
    id: "d8f5985b-9748-40ce-a617-35e376eead32",
    index: 0,
    created_at: "2022-11-24T15:12:59.318919Z",
    vid_id: "--9Zqij_5ww",
    start_time: 258,
    end_time: 271,
    created_by: null,
  },
  {
    id: "0426af3e-b3e0-4fe5-b723-2468b0d8e28d",
    index: 0,
    created_at: "2022-11-24T15:12:59.318926Z",
    vid_id: "--9Zqij_5ww",
    start_time: 343,
    end_time: 356,
    created_by: null,
  },
  {
    id: "c826cd66-1227-40aa-a417-2e475b486b2f",
    index: 0,
    created_at: "2022-11-24T15:12:59.318933Z",
    vid_id: "--9Zqij_5ww",
    start_time: 573,
    end_time: 586,
    created_by: null,
  },
  {
    id: "010b960b-0513-420f-8d51-4cb499c1adab",
    index: 0,
    created_at: "2022-11-24T15:12:59.318940Z",
    vid_id: "--9Zqij_5ww",
    start_time: 664,
    end_time: 675,
    created_by: null,
  },
  {
    id: "f45ae92f-eacb-4640-9677-e70a7133b557",
    index: 0,
    created_at: "2022-11-24T15:12:59.318946Z",
    vid_id: "--9Zqij_5ww",
    start_time: 737,
    end_time: 751,
    created_by: null,
  },
  {
    id: "3ea5229c-c4d6-4550-ac06-caf35d5fc36b",
    index: 0,
    created_at: "2022-11-24T15:12:59.318953Z",
    vid_id: "--9Zqij_5ww",
    start_time: 885,
    end_time: 895,
    created_by: null,
  },
  {
    id: "ca31ca1a-0958-4760-862d-b49a0331619f",
    index: 0,
    created_at: "2022-11-24T15:12:59.318960Z",
    vid_id: "--9Zqij_5ww",
    start_time: 979,
    end_time: 996,
    created_by: null,
  },
  {
    id: "74ba2158-64b8-4537-8e06-df8f39590630",
    index: 0,
    created_at: "2022-11-24T15:12:59.318967Z",
    vid_id: "--9Zqij_5ww",
    start_time: 1164,
    end_time: 1178,
    created_by: null,
  },
  {
    id: "9423b7ba-d57d-4335-9ca7-33cf4347fc71",
    index: 0,
    created_at: "2022-11-24T15:12:56.007617Z",
    vid_id: "--B82HvqPn0",
    start_time: 407,
    end_time: 426,
    created_by: null,
  },
  {
    id: "b601e397-61a7-4038-ba7c-8195cb0ce57d",
    index: 0,
    created_at: "2022-11-24T15:12:56.007624Z",
    vid_id: "--B82HvqPn0",
    start_time: 614,
    end_time: 625,
    created_by: null,
  },
  {
    id: "780cbca0-2e22-43ee-983f-d8e65fd1920e",
    index: 0,
    created_at: "2022-11-24T15:12:56.007630Z",
    vid_id: "--B82HvqPn0",
    start_time: 784,
    end_time: 796,
    created_by: null,
  },
  {
    id: "9f96b275-fb51-4be0-a3b8-e019f8b82dd8",
    index: 0,
    created_at: "2022-11-24T15:12:56.007637Z",
    vid_id: "--B82HvqPn0",
    start_time: 892,
    end_time: 902,
    created_by: null,
  },
  {
    id: "30de8a5f-573b-4789-8336-5b1976bb4b65",
    index: 0,
    created_at: "2022-11-24T15:12:56.007644Z",
    vid_id: "--B82HvqPn0",
    start_time: 1163,
    end_time: 1173,
    created_by: null,
  },
  {
    id: "0dda7745-7453-43b0-95c8-93c68a86d2d0",
    index: 0,
    created_at: "2022-11-24T15:12:56.007651Z",
    vid_id: "--B82HvqPn0",
    start_time: 1312,
    end_time: 1323,
    created_by: null,
  },
  {
    id: "ea6bda1c-fc04-49db-ad3c-d18207f6885b",
    index: 0,
    created_at: "2022-11-24T15:12:56.007657Z",
    vid_id: "--B82HvqPn0",
    start_time: 1396,
    end_time: 1407,
    created_by: null,
  },
  {
    id: "d42bc4e1-78ed-4519-9492-8f7ebac84e41",
    index: 0,
    created_at: "2022-11-24T15:12:56.007664Z",
    vid_id: "--B82HvqPn0",
    start_time: 1747,
    end_time: 1758,
    created_by: null,
  },
  {
    id: "3c0a620f-fe61-4199-9e84-b2d93b0331e1",
    index: 0,
    created_at: "2022-11-24T15:12:56.007672Z",
    vid_id: "--B82HvqPn0",
    start_time: 2013,
    end_time: 2031,
    created_by: null,
  },
  {
    id: "3b188c5a-d527-41ea-b32c-08eb0900c971",
    index: 0,
    created_at: "2022-11-24T15:12:56.007679Z",
    vid_id: "--B82HvqPn0",
    start_time: 2347,
    end_time: 2365,
    created_by: null,
  },
];

const StoryScreen = () => {
  const dispatch = useDispatch();
  let stories = useSelector((state) => state.story.stories);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [isContentVisible, setIsContentVisible] = useState(false); // New state variable
  const playerRef = useRef(null);

  const [autoSkipEnabled, setAutoSkipEnabled] = useState(true);

  const [totalWatchedTime, setTotalWatchedTime] = useState(0);

  useLayoutEffect(() => {
    dispatch(fetchStories());
  }, []);

  const [playerSize, setPlayerSize] = useState({ width: 860, height: 500 });

  // Check if stories is empty or blank and use localStories
  if (!stories || stories.length === 0) {
    stories = localStories;
  }

  const handleKeyPress = (event) => {
    // Check for Command + Arrow keys
    if (event.metaKey) {
      switch (event.key) {
        case "ArrowRight":
          console.log("Command + Right Arrow pressed");
          handleNextVideo();
          break;
        case "ArrowLeft":
          console.log("Command + Left Arrow pressed");
          handlePrevVideo();
          break;
        default:
          break;
      }
    } else {
      // Check for Arrow keys without Command
      switch (event.key) {
        case "ArrowRight":
          console.log("Right Arrow pressed");
          handleNextStory();
          break;
        case "ArrowLeft":
          console.log("Left Arrow pressed");
          handlePrevStory();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    console.log("StoryScreen stories", stories)
    // Cleanup event listener
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentStoryIndex, stories, dispatch]);

  // Handler to update the playerSize based on window resize
  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      if (innerWidth < 560) {
        setPlayerSize({ width: innerWidth * 0.95, height: innerWidth });
      } else {
        setPlayerSize({ width: 860, height: 500 });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNextStory = async () => {
    if (autoSkipEnabled && currentStoryIndex < stories.length - 1) {

      const currentTime = await getCurrentPlaybackTime();
      const currentStory = stories[currentStoryIndex];
      const timeSpent = currentTime - currentStory.start_time;
      
      setTotalWatchedTime((prevTime) => prevTime + timeSpent);

      const nextStoryIndex = currentStoryIndex + 1;
      const nextStory = stories[nextStoryIndex];
    
      // Only seek if the current time is before the start of the next story
      if (currentTime < nextStory.start_time) {
        seekTo(nextStory.start_time);
      }
    
      setCurrentStoryIndex(nextStoryIndex);
    } 
  };
  
  
  // Function to get current playback time from the YouTube player
  const getCurrentPlaybackTime = () => {
    return new Promise((resolve, reject) => {
      if (playerRef.current && playerRef.current.internalPlayer) {
        playerRef.current.internalPlayer
          .getCurrentTime()
          .then(resolve)
          .catch(reject);
      } else {
        resolve(0); // default value if player is not available
      }
    });
  };

  // Toggle function for autoSkipEnabled
  const toggleAutoSkip = () => {
    setAutoSkipEnabled(!autoSkipEnabled);
  };

  const handlePrevStory = async () => {

    if (currentStoryIndex > 0) {
      const prevStoryIndex = currentStoryIndex - 1;
      const prevStory = stories[prevStoryIndex];
      setCurrentStoryIndex(prevStoryIndex);
      seekTo(prevStory.start_time);
    }
  };

  // Function to control the YouTube player
  const seekTo = (time) => {
    if (playerRef.current && playerRef.current.internalPlayer) {
      playerRef.current.internalPlayer.seekTo(time);
    }
  };

  const handleNextVideo = async() => {

    const currentTime = await getCurrentPlaybackTime();
    const currentStory = stories[currentStoryIndex];
    
    // Calculate the time watched for the current story
    const timeWatchedCurrentStory = currentTime - currentStory.start_time;
    
    // Update the total watched time
    let newTotalWatchedTime = totalWatchedTime + timeWatchedCurrentStory;
    
    // Alert the total watched time and reset the state
    // alert(`Your total played time of the video is ${newTotalWatchedTime} seconds.`);
    
    // Reset the totalWatchedTime for the next video
    setTotalWatchedTime(0);

    let nextVideoIndex = currentVideoIndex;
    while (nextVideoIndex < stories.length - 1) {
      nextVideoIndex++;
      if (
        stories[nextVideoIndex]?.vid_id !== stories[currentVideoIndex]?.vid_id
      ) {
        break;
      }
    }

    if (
      nextVideoIndex < stories.length &&
      nextVideoIndex !== currentVideoIndex
    ) {
      setCurrentVideoIndex(nextVideoIndex);
      setCurrentStoryIndex(nextVideoIndex);
    }

    const watchTimeObject = {
      watch_time: newTotalWatchedTime
    }

    if (currentStory) {
      dispatch(saveHistory({...currentStory, ...watchTimeObject}));
    }

  };

  const handlePrevVideo = () => {
    let prevVideoIndex = currentVideoIndex;
    while (prevVideoIndex > 0) {
      prevVideoIndex--;
      if (
        stories[prevVideoIndex]?.vid_id !== stories[currentVideoIndex]?.vid_id
      ) {
        break;
      }
    }

    if (prevVideoIndex >= 0 && prevVideoIndex !== currentVideoIndex) {
      setCurrentVideoIndex(prevVideoIndex);
      setCurrentStoryIndex(prevVideoIndex);
    }
  };

  const handleVideoError = () => {
    console.log("Handling video error, skipping to next video");
    // handleNextVideo();
    let nextVideoIndex = currentVideoIndex;
    while (nextVideoIndex < stories.length - 1) {
      nextVideoIndex++;
      if (
        stories[nextVideoIndex]?.vid_id !== stories[currentVideoIndex]?.vid_id
      ) {
        break;
      }
    }

    if (
      nextVideoIndex < stories.length &&
      nextVideoIndex !== currentVideoIndex
    ) {
      setCurrentVideoIndex(nextVideoIndex);
      setCurrentStoryIndex(nextVideoIndex);
    }
  };

  // Pass the current story to the YoutubeComponent
  const currentStory = stories[currentStoryIndex] || {};
  const currentVideoId = currentStory.vid_id || "";

  const currentVideoStories = stories.filter(
    (story) => story.vid_id === currentVideoId
  );

  const totalStories = currentVideoStories.length;
  const storyIndexInVideo = currentVideoStories.findIndex(
    (story) => story.id === currentStory.id
  );

  const handleClick = () => {
    setIsContentVisible(true);
  };

  const handleVideoPlay = () => {
    console.log("Video player: Playing video");
  };

  return (
    <>
      <div className="container" style={{ color: "white" }}>
        {!isContentVisible && (
          <div id="youtubediv" onClick={handleClick}>
            <h5 className="heading">ELSE</h5>
            <p className="click-button">Click to open</p>
          </div>
        )}
        {isContentVisible && (
          <>
            <ProgressBar
              totalStories={totalStories}
              currentStoryIndex={storyIndexInVideo}
              width={playerSize.width}
            />
            {currentStory.vid_id && (
              <YoutubeComponent
                key={currentStory.vid_id}
                story={currentStory}
                playerRef={playerRef}
                onVideoEnd={handleNextStory}
                onLastStoryEnd={handleNextVideo}
                endTime={currentStory.end_time}
                onError={handleVideoError}
                onPlay={handleVideoPlay}
              />
            )}

            <div className="button-section">
              <div
                className="button-background-circle"
                onClick={handlePrevVideo}
              >
                <FaAngleDoubleLeft color="white" size={25} />
              </div>
              <div
                className="button-background-circle"
                onClick={handlePrevStory}
              >
                <FaAngleLeft color="white" size={25} />
              </div>
              {/* This is toggleAutoSkip button */}
              <div
                style={{ color: "white" }}
                className="button-background-circle"
                onClick={toggleAutoSkip}
              >
                {/* Use an appropriate icon or text here */}
                {autoSkipEnabled ? (
                  <div className="button-background-circle">
                    <VscDebugContinue color="white" size={25} />
                  </div>
                ) : (
                  <div className="button-background-circle">
                    <FaRegStopCircle color="white" size={25} />
                  </div>
                )}
              </div>
              <div
                className="button-background-circle"
                onClick={handleNextStory}
              >
                <FaAngleRight color="white" size={25} />
              </div>
              <div
                className="button-background-circle"
                onClick={handleNextVideo}
              >
                <FaAngleDoubleRight color="white" size={25} />
              </div>
            </div>
          </>
        )}
      </div>
      <History />
    </>
  );
};

export default StoryScreen;
