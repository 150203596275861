import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MenuOpenIcon from "@mui/icons-material/MenuOpen"; // Import MenuOpenIcon
import {
  AiFillCloseCircle,
  AiOutlineMenu,
  AiOutlineClose,
} from "react-icons/ai";
import "./history.css";

function App() {
  const { collapseSidebar, toggleSidebar, collapsed, toggled } =
    useProSidebar();

  useEffect(() => {
    toggleSidebar();
  }, []);

  const toggle = () => {
    toggleSidebar();
  };

  function getYoutubeLink(videoId) {
    return `https://www.youtube.com/watch?v=${videoId}`;
  }

  const savedHistory = useSelector((state) => state.history.saved_history);

  const reversedHistory = [...savedHistory].reverse();

  const getThumbnailLink = (id) => `https://img.youtube.com/vi/${id}/0.jpg`;

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  return (
    <div
      id="app"
      style={{ height: "100vh", display: "flex", flexDirection: "row" }}
      className="sidebar"
    >
      {!toggled && (
        <Sidebar
          backgroundColor="rgba(0, 0, 0, 0.6)"
          rtl={false}
          collapsed={collapsed}
          toggled={toggled}
          style={{ height: "100vh" }}
          className="react-pro-sidebar"
          width="180px"
        >
          <div style={{ fontSize: 21 }} className="sigma-icon" onClick={toggle}>
            X
          </div>
          {/* <MenuOutlinedIcon
            
            
          /> */}
          <div className="watch-list-container">
            {/* <div>{JSON.stringify(savedHistory)}</div> */}
            { reversedHistory&&
              reversedHistory.map((item, index) => (
                <div className="history-item">
                  <a
                    href={getYoutubeLink(item.vid_id)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="thumbnail-container"
                    style={{ backgroundImage: `url(${getThumbnailLink(item.vid_id)})` }}
                  >
                    <span className="overlay-text">{Math.round(item.watch_time)}</span>
                  </a>
                  {/* <div>
                    <div style={{ color: "#a6a6a6" }}>
                      <span className="channel-title">
                        {item.channel_title}
                      </span>{" "}
                      - <span className="video-title">{item.video_title}</span>
                    </div>
                  </div> */}
                  {/* <div>
                    <a
                      href={getYoutubeLink(item.vid_id)}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{fontSize: 12, width: '70%'}}
                    >
                      {getYoutubeLink(item.vid_id)}
                    </a>
                  </div> */}
                </div>
              ))}
          </div>
        </Sidebar>
      )}

      {toggled && (
        // <div style={{}}>
        <div className="sigma-icon" onClick={toggle}>
          Σ
        </div>
        // </div>
      )}
    </div>
  );
}

export default App;
