// import React from 'react';
// import "../index.css"
// import { useRef } from 'react';


// const YTPlayer = require('yt-player')
// const { innerWidth } = window;

// function YoutubeComponent({ stories }) {
//     // console.log(stories)

//     const ref = useRef(null);
//     let index = 0;

//     // const [currentTime, setCurrentTime] = useState(stories[index]?.start_time ?? 0);
//     // const [playing, setPlaying] = useState(true)
//     // const [storyId, setStoryId] = useState(stories[index]?.vid_id ?? "GfO-3Oir-qM")

//     const handleClick = () => {
//         const player = new YTPlayer('#youtubediv')
//         if (innerWidth < 560) {
//             player.setSize(innerWidth, innerWidth * 0.95)
//         }
        
//         // console.log("currenTime :", currentTime)
//         player.load(stories[index]?.vid_id ?? "GfO-3Oir-qM", true, [stories[index]?.start_time ?? 0])
//         player.setVolume(100)
//         console.log(YTPlayer)
//         player.on('playing', () => {

//             console.log("getDuration", player.getDuration())
//         })
//         setInterval(() => {
//             console.log('timeStamp', player.getCurrentTime(), player.getState(), player.videoId)
//             const end = stories[index]?.end_time ?? 100000000
//             const start = stories[index]?.start_time ?? 0
            

//             if ((player.getCurrentTime() >= (end))||(player.getCurrentTime() <= (start))) {
//                 index += 1;
//                 const newStart = stories[index]?.start_time ?? 0
//                 const videoId = stories[index]?.vid_id ?? "GfO-3Oir-qM"
//                 // setCurrentTime(stories[index]?.start_time ?? 0)
                
//                 if (player.videoId !== videoId){
//                     player.load(videoId, [true, [newStart]])
//                 }
//                 player.seek(newStart)
//                 console.log("Updating to",  index, newStart, stories[index]?.end_time ?? 0)
//             }
//             else if (player.getCurrentTime() === 0) {
//                 console.log('else if condition')
//                 player.seek(start)
//             }
//         }, 2000);
//     }
//     return (
//         <div
//             ref={ref}
//             id="youtubediv"
//             onClick={handleClick}
//             >
//             <h5 className='heading'>ELSE</h5>
//             <p
//                 className='click-button'
                
//             >Click to open</p>
//         </div>
//     )
// }

// export default YoutubeComponent


import React, { useRef, useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import "../index.css"

const YoutubeComponent = ({ story, playerRef, onVideoEnd, onLastStoryEnd, endTime, onError }) => {

  const [playerSize, setPlayerSize] = useState({ width: 860, height: 500 });

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;
      if (innerWidth < 560) {
        setPlayerSize({ width: innerWidth * 0.95, height: innerWidth });
      } else {
        setPlayerSize({ width: 860, height: 500 });
      }
    };
  
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  useEffect(() => {
    const checkTime = () => {
      if (playerRef.current && playerRef.current.internalPlayer) {
        playerRef.current.internalPlayer.getCurrentTime().then(currentTime => {
          if (currentTime >= endTime) {
            onVideoEnd();
          }
        });
      }
    };

    const timeCheckInterval = setInterval(checkTime, 1000); // Check every second

    return () => clearInterval(timeCheckInterval); // Clear interval on component unmount
  }, [story, endTime, onVideoEnd]);

  const opts = {
    height: playerSize.height.toString(),
    width: playerSize.width.toString(),
    playerVars: {
      autoplay: 1,
      // other player variables
    },
  };

  // Function to seek to the start time of the story
  const handlePlayerReady = (event) => {
    // Check if the story has a start_time and seek to it
    if (story && story.start_time) {
      event.target.seekTo(story.start_time);
    }
  };

  const handleVideoEnd = () => {
    const currentTime = playerRef.current.internalPlayer.getCurrentTime();
    console.log("Current Time: ", currentTime, " End Time: ", endTime);
    if (currentTime >= endTime) {
      console.log("Moving to next story");
      onVideoEnd();
    } else {
      console.log("Moving to next video");
      onLastStoryEnd();
    }
  };
  
  const handleError = (event) => {
    console.error("YouTube Player Error: ", event.data);
    onError(); // Call the onError function passed as prop
  };

  return (
    <div>
    <YouTube
        videoId={story?.vid_id}
        opts={opts}
        ref={playerRef}
        onReady={handlePlayerReady}
        onStateChange={(e) => {
          console.log("State Changed: ", e.data);
          if (e.data === 0) handleVideoEnd();
        }}
        onError={handleError}
      />
    </div>
  );
};

export default YoutubeComponent;
