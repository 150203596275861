const initState = {
  saved_history: [],
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case "SAVE_HISTORY": {
        // console.log("SAVE_HISTORY action.payload", action.payload.vid_id)
        // console.log("SAVE_HISTORY state.saved_history", state.saved_history)
      const videoExists = state.saved_history.some(
        (video) => video.vid_id === action.payload.vid_id
      );
      if (!videoExists) {
        return {
          ...state,
          saved_history: [...state.saved_history, action.payload],
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}
