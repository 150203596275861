import data from '../config/data.json'

// export const fetchStories = () => async (dispatch, getState) => {
//     console.log("fetchStories function called.")
//     console.log("Story.action.js fetchStories() data.storyList", data.storyList)
//     try {
//         const page = Math.floor(Math.random()*1331).toString()
//         const url = "https://api.else.live/api/v1/stories/?page="+page
//         const response = await fetch(url, {
//             method: "GET",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json"
//             }
//         })

//         const json = await response.json();
//         if ("status" in response && response.status < 400) {
//             const data = json
//             const storyList = data.results
//             // console.log("fetchStories storyList : ", storyList)
//             dispatch({
//                 type: "FETCH_STORIES",
//                 payload: {storyList}
//             })
//         } else {
//             // If the API fails, use storyList from data.json
//             const storyList = data.storyList; // Assuming data.json contains an array named storyList
//             console.log("Using storyList from data.json: ", storyList)
//             dispatch({
//                 type: "FETCH_STORIES",
//                 payload: {storyList}
//             })
//         }
//     } catch (err) {
//         console.log("fetchStories error", err.message)
//         // Fallback to using data from data.json on any error
//         const storyList = data.storyList; // Assuming data.json contains an array named storyList
//         console.log("Using storyList from data.json due to error: ", storyList)
//         dispatch({
//             type: "FETCH_STORIES",
//             payload: {storyList}
//         })
//     }
// }


export const fetchStories = () => async (dispatch, getState) => {
    console.log("fetchStories function called.")
    try {
        const page = Math.floor(Math.random()*1331).toString()
        const url = "https://api.else.live/api/v1/stories/?page="+page
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })

        const json = await response.json();
        if ("status" in response && response.status < 400) {
            const data = json
            const storyList = data.results
            // console.log("fetchStories storyList : ", storyList)
            dispatch({
                type: "FETCH_STORIES",
                payload: {storyList}
            })
        }
    } catch (err) {
        console.log("fetchStories error", err.message)
    }
}

