export const saveHistory = (historyObject) => async (dispatch, getState) => {
    console.log("History.action.js saveHistory() function called historyObject", historyObject)
    try {
        dispatch({
            type: 'SAVE_HISTORY',
            payload: historyObject
        })
    } catch (err) {
        console.log("History.action.js saveHistory()", err.message)
    }
}